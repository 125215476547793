import React from "react";
import "./App.css";
import Logo from "./unboxed-logo.svg"

function App() {
  return (
    <>
      <img src={Logo} alt="Your SVG" />
      <h1 style={{ textAlign: "center" }}>Coming Soon . . .</h1>
    </>
  );
}

export default App;
